import logo from './logo.jpeg';
import sponsors from './sponsors.jpg';
import './App.css';
import fivepack from './Unfiltered_Pachet_5TokensGlass.jpg';
import tenpack from './Unfiltered_Pachet_10TokensGlass.jpg';
import twenty from './Unfiltered_Pachet_20TokensGlass.jpg';
import insta from './insta.png';
import fb from './fb.png';

function App() {
	return (
		<div className="App">
		    <div id='socials'>
				<a href='https://www.instagram.com/unfiltered.festival/' className="App-link">
					<img src={insta} className="Social" alt="Instagram"/>
				</a>
				<a href='https://www.facebook.com/events/1579873589538333' className="App-link">
					<img src={fb} className="Social" alt="Facebook"/>
				</a>
			</div>
			<a href='' className="App-link"></a>
			<img src={logo} className="App-logo" alt="logo"/>
			<div id='products'>
				<a
					className="App-link"
					href="https://one-two.ro/en/packs/187-unfiltered-taster-pack.html"
					target="_blank"
					rel="noopener noreferrer"
				>	
					<span>Taster Pack</span>
					<img src={fivepack} className="FivePack" alt="Unfiltered 5 tokens pack"/>
				</a>
				<a
					className="App-link"
					href="https://one-two.ro/en/packs/188-unfiltered-explorer-pack.html"
					target="_blank"
					rel="noopener noreferrer"
				>
					<span>Explorer Pack</span>
					<img src={tenpack} className="TenPack" alt="Unfiltered 10 tokens pack"/>
				</a>
				<a
					className="App-link"
					href="https://one-two.ro/en/packs/189-unfiltered-connoisseur-pack.html"
					target="_blank"
					rel="noopener noreferrer"
				>
					<span>Connoisseur Pack</span>
					<img src={twenty} className="Twenty" alt="Unfiltered 20 tokens pack"/>
				</a>
			</div>
			<img src={sponsors} className="Sponsors" alt="Unfiltered partners"/>
		</div>
	);
}

export default App;
